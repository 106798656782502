class PdfShape {
  constructor() {
    this.minWidth = 100;
    this.minHeight = 30;
    this.padding = 10;
    this.pixelRatio = Math.max(window.devicePixelRatio || 1, 1);
  }

  drawSignature(attrs, options) {
    var pixelRatio = attrs.pixelRatio || this.pixelRatio;

    var group = new Konva.Group({
      id: attrs.id,
      name: attrs.name,
      page: attrs.page,
      role: attrs.role,
      x: attrs.x,
      y: attrs.y,
      width: Math.max(attrs.width, this.minWidth),
      height: Math.max(attrs.height, this.minHeight),
      scaleX: attrs.scaleX || 1,
      scaleY: attrs.scaleY || 1,
      pixelRatio: pixelRatio,
      pageScale: attrs.pageScale,
      visible: options.visible,
      draggable: options.draggable,
      dragBoundFunc: this.dragBoundFunc
    });

    var shape = new Konva.Rect({
      id: attrs.id + 'rect',
      width: Math.max(attrs.width, this.minWidth),
      height: Math.max(attrs.height, this.minHeight),
      pixelRatio: pixelRatio,
      strokeWidth: 1,
      dash: [4, 4],
      strokeScaleEnabled: false,
      keepRatio: true,
      fillEnabled: true,
      stroke: '#e5b94b'
    });

    var label = new Konva.Text({
      text: 'Signature',
      fontSize: 10,
      lineHeight: 1,
      padding: 10,
      fill: '#e5b94b',
      scaleX: (1 / attrs.scaleX || 1),
      scaleY: (1 / attrs.scaleY || 1)
    });

    group.add(shape);
    group.add(label);

    if (attrs.fillPatternImage instanceof HTMLImageElement) {
      shape.fillPatternScale({ x: (1 / pixelRatio), y: (1 / pixelRatio) });
      shape.fillPatternImage(attrs.fillPatternImage);
    }
    else {
      shape.fill('#fbf5e8');
    }

    group.on('transform', function () {
      var absScale = label.getAbsoluteScale();

      label.scaleX(label.scaleX() / absScale.x);
      label.scaleY(label.scaleY() / absScale.y);
    });

    group.on('transformstart', function (e) {
      options.onUpdateShapeTools(false, e);
    });

    group.on('transformend', function (e) {
      options.onUpdateShapeTools(true, e);
      options.onShapeChange(this);
    });

    group.on('dragstart', function (e) {
      options.onUpdateShapeTools(false, e);
    });

    group.on('dragend', function (e) {
      options.onUpdateShapeTools(true, e);
      options.onShapeChange(this);
    });

    return group;
  }

  fillSignatureImage(shape, shapeAttrs, signatureData, onload) {
    var pixelRatio = shapeAttrs.pixelRatio || this.pixelRatio;
    var signatureImg = new Image();

    signatureImg.onload = function () {
      shapeAttrs.fillPatternImage = signatureImg;
      if (shape) {
        shape.fill(null);
        shape.fillPatternScale({ x: (1 / pixelRatio), y: (1 / pixelRatio) });
        shape.fillPatternImage(signatureImg);
      }

      onload();
    };

    this.resizeDataURL(signatureData, shapeAttrs, pixelRatio).then(function (resizedImg) {
      signatureImg.src = resizedImg;
    });
  }

  resizeDataURL(data, shapeAttrs, pixelRatio) {
    return new Promise(function (resolve) {
      var img = new Image();

      img.onload = function () {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');

        canvas.width = shapeAttrs.width * pixelRatio;
        canvas.height = shapeAttrs.height * pixelRatio;

        ctx.fillStyle = '#fbf5e8';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        resolve(canvas.toDataURL());
      };

      img.src = data;
    });
  }

  drawText(attrs, options) {
    var shape = new Konva.Text({
      id: attrs.id,
      name: attrs.name,
      page: attrs.page,
      role: attrs.role,
      x: attrs.x,
      y: attrs.y,
      width: Math.max(attrs.width, this.minWidth),
      height: Math.max(attrs.height, this.minHeight),
      pageScale: attrs.pageScale,
      text: attrs.text,
      fontSize: 12,
      fontFamily: 'Arial',
      fill: '#1484c9',
      padding: 10,
      visible: options.visible,
      draggable: options.draggable,
      dragBoundFunc: this.dragBoundFunc
    });

    shape.on('transform', function () {
      this.setAttrs({
        width: _.round(this.width() * this.scaleX()),
        scaleX: 1
      });
    });

    shape.on('transformstart', function (e) {
      options.onUpdateShapeTools(false, e);
    });

    shape.on('transformend', function (e) {
      options.onUpdateShapeTools(true, e);
      options.onShapeChange(this);
    });

    shape.on('dragstart', function (e) {
      options.onUpdateShapeTools(false, e);
    });

    shape.on('dragend', function (e) {
      options.onUpdateShapeTools(true, e);
      options.onShapeChange(this);
    });

    return shape;
  }

  getSignatureTransformer(draggable) {
    var transformer = {
      rotateEnabled: false,
      anchorStroke: '#999',
      borderStroke: '#999',
      boundBoxFunc: this.boundBoxFunc
    };

    if (!draggable) {
      transformer.enabledAnchors = [];
    }

    return new Konva.Transformer(transformer);
  }

  getTextTransformer(draggable) {
    var anchors = draggable ? ['middle-left', 'middle-right'] : [];

    return new Konva.Transformer({
      rotateEnabled: false,
      anchorStroke: '#999',
      borderStroke: '#999',
      enabledAnchors: anchors,
      boundBoxFunc: this.boundBoxFunc
    });
  }

  dragBoundFunc(pos) {
    let padding;

    if (typeof this.padding === 'function') {
      padding = this.padding();
    }
    else {
      padding = this.padding || 10;
    }

    var width = this.attrs.scaleX ? this.attrs.scaleX * this.attrs.width : this.attrs.width;
    var height = this.attrs.scaleY ? this.attrs.scaleY * this.attrs.height : this.attrs.height;

    var rightBound = this.getStage().width() - width - padding;
    var bottomBound = this.getStage().height() - height - padding;

    var newX = pos.x > rightBound ? rightBound : Math.max(pos.x, padding);
    var newY = pos.y > bottomBound ? bottomBound : Math.max(pos.y, padding);

    return { x: newX, y: newY };
  }

  boundBoxFunc(oldBox, newBox) {
    if (newBox.width < this.minWidth || newBox.height < this.minHeight || newBox.x < this.padding || newBox.y < this.padding) {
      return oldBox;
    }
    else {
      var rightBound = this.getStage().width() - this.padding;
      var bottomBound = this.getStage().height() - this.padding;

      var right = newBox.x + newBox.width;
      var bottom = newBox.y + newBox.height;

      if (right > rightBound || bottom > bottomBound) {
        return oldBox;
      }
      else {
        return newBox;
      }
    }
  }
}

const instance = new PdfShape();
Object.freeze(instance);

export default instance;
