<template>
  <modal v-model="modalOpen" size="lg" :header="false" :footer="false" :backdrop="false" append-to-body>
    <div class="notice-modal">
      <modal-close-button :on-dismiss="cancel"></modal-close-button>

      <div class="notice-regular-text">
        <div class="size-text-base semibold-weight bottom-20">
          <div v-if="isLawyer && isOnStep(steps.define)">
            Engagement Letter (Add Fields)
          </div>

          <div v-if="isLawyer && isOnStep(steps.sign)">
            Engagement Letter (Sign Document)
          </div>

          <div v-if="isClient">
            Engagement Letter (Sign Document)
          </div>
        </div>

        <div class="bottom-30">
          <div v-if="isLawyer && isOnStep(steps.define)">
            Select from the fields on the right and add them to the document. <span class="red-text">* Indicates required field</span>
          </div>

          <div v-if="isLawyer && isOnStep(steps.sign)">
            Add your signature {{ showTextField ? 'and text' : '' }} on the right and then Save & Close.
          </div>

          <div v-if="isClient">
            Add your signature {{ showTextField ? 'and text' : '' }} on the right and then Save & Close. The signed document will be provided to the attorney and saved in the Documents section of your project, once open.
          </div>
        </div>

        <div class="engagement-letter">
          <div class="row">
            <div class="col-sm-8">
              <div class="row">
                <div class="col-sm-4 bottom-10">
                  <a href="" class="bold-link blue-link" @click.prevent="prevPage" :disabled="isFirstPage()">Previous Page</a>
                </div>

                <div class="col-sm-4 dark-gray-text size-text-12px text-center top-2 bottom-10">
                  Page {{ pageNum }} of {{ numPages }}
                </div>

                <div class="col-sm-4 text-right bottom-10">
                  <a href="" class="bold-link blue-link" @click.prevent="nextPage" :disabled="isLastPage()">Next Page</a>
                </div>
              </div>

              <div class="pdf-wrapper">
                <div :id="containerId" :ref="containerId"></div>

                <div :class="['shape-tools', { 'is-visible': showShapeTools }]" :style="shapeToolStyle">
                  <div class="clickable" @click="destroyShape">
                    <svg-icon name="x3" class="deselect-icon size-text-16px"></svg-icon>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-4">
              <div v-show="isLawyer && isOnStep(steps.define)">
                <div class="semibold-weight bottom-10">
                  Add Client Fields
                </div>

                <div class="bottom-10">
                  <button type="button" :class="['nv-button-white smaller', { 'selected': isDrawingShape(shapeTypes.clientSignature) }]" @click.prevent="startAddShape(shapeTypes.clientSignature)" :disabled="hasShape(shapeTypes.clientSignature)">Signature Block <span class="red-text"> *</span></button>
                </div>

                <div class="bottom-10">
                  <button type="button" :class="['nv-button-white smaller', { 'selected': isDrawingShape(shapeTypes.clientText) }]" @click.prevent="startAddShape(shapeTypes.clientText)" :disabled="hasShape(shapeTypes.clientText)">Text Field</button>
                </div>

                <div :class="{ 'bottom-30': !isSavedDocument }">
                  <button type="button" :class="['nv-button-white smaller', { 'selected': isDrawingShape(shapeTypes.clientDate) }]" @click.prevent="startAddShape(shapeTypes.clientDate)" :disabled="hasShape(shapeTypes.clientDate)">Date Field</button>
                </div>

                <div v-if="!isSavedDocument">
                  <div class="semibold-weight bottom-10">
                    Add Attorney Fields
                  </div>

                  <div class="bottom-10">
                    <button type="button" :class="['nv-button-white smaller', { 'selected': isDrawingShape(shapeTypes.lawyerSignature) }]" @click.prevent="startAddShape(shapeTypes.lawyerSignature)" :disabled="hasShape(shapeTypes.lawyerSignature)">Signature Block</button>
                  </div>

                  <div class="bottom-10">
                    <button type="button" :class="['nv-button-white smaller', { 'selected': isDrawingShape(shapeTypes.lawyerText) }]" @click.prevent="startAddShape(shapeTypes.lawyerText)" :disabled="hasShape(shapeTypes.lawyerText)">Text Field</button>
                  </div>

                  <div>
                    <button type="button" :class="['nv-button-white smaller', { 'selected': isDrawingShape(shapeTypes.lawyerDate) }]" @click.prevent="startAddShape(shapeTypes.lawyerDate)" :disabled="hasShape(shapeTypes.lawyerDate)">Date Field</button>
                  </div>
                </div>

                <hr class="kenny-hr wide">

                <div v-if="hasLawyerShapes()">
                  <button type="button" class="nv-button-blue smaller" :disabled="saving || isInvalid()" @click.prevent="goToStep(steps.sign)">Next Step</button>
                </div>

                <div v-if="!hasLawyerShapes()">
                  <button type="button" class="nv-button-blue smaller" :disabled="saving || isInvalid()" @click.prevent="submit">Save & Close</button>
                </div>
              </div>

              <div v-show="isOnStep(steps.sign)">
                <form role="form" class="a-form" novalidate>
                  <div v-show="showSignatureField">
                    <div class="row bottom-10">
                      <div class="col-xs-6 semibold-weight">
                        Sign Here
                      </div>

                      <div class="col-xs-6 text-right-not-xs">
                        <a href="" class="bold-link" :disabled="!hasSignature()" @click.prevent="clearSignaturePad">Clear</a>
                      </div>
                    </div>

                    <div class="signature-pad bottom-20">
                      <canvas :id="signaturePadId" :ref="signaturePadId"></canvas>
                    </div>
                  </div>

                  <div v-show="showTextField">
                    <label for="text-field">Add Text</label>

                    <div class="bottom-20">
                      <input type="text" name="textField" id="text-field" class="form-control" autocomplete="off" @focus="textFieldFocused()" @blur="textFieldBlurred()" v-model="text">
                    </div>
                  </div>

                  <div v-show="showDateField">
                    <label for="date-field">Add Date</label>

                    <dropdown class="form-group uiv-datepicker">
                      <div class="input-group datepicker-group">
                        <input type="text" name="dateField" id="date-field" class="form-control" placeholder="mm/dd/yyyy" autocomplete="off" v-model="date">

                        <span class="input-group-btn">
                          <button type="button" class="btn btn-default"><svg-icon name="calendar" class="button-icon"></svg-icon></button>
                        </span>
                      </div>

                      <template #dropdown>
                        <li>
                          <date-picker
                            v-model="date"
                            format="MM/dd/yyyy"
                            :today-btn="false"
                            :clear-btn="false"
                            :date-class="dateClass"
                            :year-month-formatter="dateYearMonthFormat"
                            :width="null">
                          </date-picker>
                        </li>
                      </template>
                    </dropdown>
                  </div>

                  <hr class="kenny-hr wide">

                  <div>
                    <button type="button" class="nv-button-blue smaller" :disabled="saving || isInvalid()" @click.prevent="submit">Save & Close</button>
                  </div>

                  <div class="top-15" v-if="isLawyer">
                    <button type="button" class="nv-button-white smaller" :disabled="saving" @click.prevent="goToStep(steps.define)">Back</button>
                  </div>
                </form>
              </div>

              <div class="top-15">
                <button type="button" class="nv-button-white smaller" @click="cancel()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import modalToggle from 'vue-app/shared/mixins/modal-toggle';
import Pdf from 'vue-app/marketplace/shared/engagement-letter/pdf-renderer';
import PdfDrawingArea from 'vue-app/marketplace/shared/engagement-letter/pdf-drawing-area';
import PdfShape from 'vue-app/marketplace/shared/engagement-letter/pdf-shape';
import Document from 'resources/document';
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import DateFilter from 'vue-app/shared/lib/date-filter';
import { each, find, includes, reject, some } from 'lodash';

export default {
  name: 'EngagementLetterModal',

  components: {
    ModalCloseButton,
    SvgIcon
  },

  mixins: [
    modalToggle
  ],

  props: {
    engagementLetterConfig: {
      type: Object,
      required: true
    },

    onSubmit: {
      type: Function,
      required: true
    },

    onDismiss: {
      type: Function,
      required: false
    },

    isSavedDocument: {
      type: Boolean,
      default: false
    },

    showModal: {
      type:    Boolean,
      default: false
    }
  },

  data() {
    return {
      steps: {
        define: 'define',
        sign: 'sign'
      },
      step: null,
      shapeTypes: {
        lawyerSignature: 'lawyerSignature',
        lawyerText: 'lawyerText',
        lawyerDate: 'lawyerDate',
        clientSignature: 'clientSignature',
        clientText: 'clientText',
        clientDate: 'clientDate'
      },
      drawingShape: null,
      isDrawing: false,
      selectedShape: null,
      showShapeTools: false,
      pixelRatio: PdfShape.pixelRatio,
      pdfLoaded: false,
      numPages: 0,
      pageNum: 0,
      signaturePad: null,
      signatureData: null,
      shapeToolStyle: {},
      saving: false,
      text: null,
      date: null
    };
  },

  computed: {
    containerId() {
      return `pdf-container-${this.engagementLetterConfig.matterId}`;
    },

    signaturePadId() {
      return `signature-pad-${this.engagementLetterConfig.matterId}`;
    },

    shapes() {
      if (this.isSavedDocument) {
        // Prevent redraw of existing lawyer shapes on saved doc
        return reject(this.engagementLetterConfig.coord || [], (shape) => {
          return includes(shape.name, 'lawyer');
        });
      }
      else {
        return this.engagementLetterConfig.coord || [];
      }
    },

    signature() {
      return this.engagementLetterConfig.signature;
    },

    documentUrl() {
      return this.engagementLetterConfig.documentUrl;
    },

    role() {
      return this.engagementLetterConfig.role;
    },

    isClient() {
      return this.role === 'client';
    },

    isLawyer() {
      return this.role === 'lawyer';
    },

    showSignatureField() {
      return (this.isLawyer && this.hasShape(this.shapeTypes.lawyerSignature)) ||
        (this.isClient && this.hasShape(this.shapeTypes.clientSignature));
    },

    showTextField() {
      return (this.isLawyer && this.hasShape(this.shapeTypes.lawyerText)) ||
        (this.isClient && this.hasShape(this.shapeTypes.clientText));
    },

    showDateField() {
      return (this.isLawyer && this.hasShape(this.shapeTypes.lawyerDate)) ||
        (this.isClient && this.hasShape(this.shapeTypes.clientDate));
    },

    isShapeDraggable() {
      return this.isLawyer;
    }
  },

  watch: {
    text(text) {
      if (this.pdfLoaded) {
        let textShape = this.getTextShape();

        if (textShape) {
          if (!text || text.length === 0) {
            if (this.selectedShape) {
              this.selectedShape.text('* Text');
            }
            textShape.text = '* Text';
          }
          else {
            if (this.selectedShape) {
              this.selectedShape.text(text);
            }

            textShape.text = text;
          }

          Pdf.drawLayer.draw();
        }
      }
    },

    date(date) {
      let dateShape = this.getDateShape();

      if (dateShape) {
        let drawnShape = Pdf.stage.findOne('#' + dateShape.id);
        date = this.convertDate(date);

        if (!date || date.length === 0) {
          if (drawnShape) {
            drawnShape.text('* Date');
          }
          dateShape.text = '* Date';
        }
        else {
          if (drawnShape) {
            drawnShape.text(date);
          }
          dateShape.text = date;
        }

        Pdf.drawLayer.draw();
      }
    },

    showModal(open) {
      if (open) {
        this.init();
        this.openModal();
      }
      else {
        this.closeModal();
      }
    }
  },

  methods: {
    init() {
      this.loadPDF();

      if (this.isLawyer) {
        this.goToStep(this.steps.define);
      }
      else {
        this.goToStep(this.steps.sign);
      }

      this.loadSignaturePad();
      this.resetForm();
    },

    // ********** General Functions **********

    isOnStep(step) {
      return this.step === step;
    },

    goToStep(step) {
      if (this.isLawyer && step === this.steps.define) {
        this.loadEventHandlers();
        this.setDraggability(true);
        this.step = this.steps[step];
      }
      else if (step === this.steps.sign) {
        if (this.isLawyer) {
          this.unloadEventHandlers();
          this.setDraggability(false);
          this.deselectAllShapes();
        }
        this.step = this.steps[step];
      }
    },

    setDraggability(draggable) {
      if (!Pdf.stage) { return; }

      Pdf.stage.getChildren().each(function (layer) {
        layer.getChildren().each(function (shapeOrGroup) {
          shapeOrGroup.draggable(draggable);
        });
      });
    },

    cancel() {
      this.clearSignaturePad();
      this.date = null;
      this.text = null;

      if (this.onDismiss) { this.onDismiss(); }

      this.dismissModal();
    },

    // ********** PDF Drawing Functions **********

    loadPDF() {
      this.drawingArea = new PdfDrawingArea();

      const vueInstance = this;

      setTimeout(() => {
        const width = vueInstance.$refs[vueInstance.containerId].offsetWidth - 2;

        Pdf.init(vueInstance.documentUrl, vueInstance.containerId, width, vueInstance.renderShapes, () => {
          vueInstance.pdfLoaded = true;
          vueInstance.numPages = Pdf.numPages;
          vueInstance.goToPdfSignaturePage();
        });
      });
    },

    renderShapes() {
      this.drawingArea.init(Pdf.drawLayer);

      if (this.isLawyer && this.isOnStep(this.steps.define)) {
        this.updateShapeTools(false);
      }

      const pageShapes = this.shapes.filter(shape => shape.page === this.pageNum);

      each(pageShapes, (shape) => {
        Pdf.drawShape(shape, this.genericTypeOf(shape), {
          visible: this.isShapeVisible(shape),
          draggable: this.isShapeDraggable,
          onUpdateShapeTools: this.updateShapeTools,
          onShapeChange: this.shapeChanged
        });
      });
    },

    genericTypeOf(shape) {
      return includes(shape.name, 'Signature') ? 'signature' : 'text';
    },

    isShapeVisible(shape) {
      return this.isLawyer || (this.isClient && includes(shape.name, 'client'));
    },

    isFirstPage() {
      return Pdf.isFirstPage();
    },

    isLastPage() {
      return Pdf.isLastPage();
    },

    prevPage() {
      Pdf.prevPage();
      this.pageNum = Pdf.pageNum;
    },

    nextPage() {
      Pdf.nextPage();
      this.pageNum = Pdf.pageNum;
    },

    goToPdfSignaturePage() {
      let signatureShape = this.getSignatureShape() || { page: 1 };

      Pdf.goToPage(signatureShape.page);
      this.pageNum = Pdf.pageNum;
    },

    startAddShape(shapeType) {
      this.deselectAllShapes();

      if (!this.hasShape(shapeType)) {
        this.drawingShape = this.shapeTypes[shapeType];
      }
    },

    isDrawingShape(shapeType) {
      return this.drawingShape && this.drawingShape === shapeType;
    },

    deselectAllShapes() {
      this.selectedShape = null;
      this.showShapeTools = false;
      Pdf.stage.find('Transformer').destroy();
      Pdf.drawLayer.draw();
    },

    hasShape(shapeType) {
      return some(this.shapes, { name: shapeType });
    },

    hasLawyerShapes() {
      return some(this.shapes, function (shape) {
        return includes(shape.name, 'lawyer');
      });
    },

    getSignatureShape() {
      if (this.isLawyer) {
        return find(this.shapes, { name: this.shapeTypes.lawyerSignature });
      }
      else {
        return find(this.shapes, { name: this.shapeTypes.clientSignature });
      }
    },

    getTextShape() {
      if (this.isLawyer) {
        return find(this.shapes, { name: this.shapeTypes.lawyerText });
      }
      else {
        return find(this.shapes, { name: this.shapeTypes.clientText });
      }
    },

    getDateShape() {
      if (this.isLawyer) {
        return find(this.shapes, { name: this.shapeTypes.lawyerDate });
      }
      else {
        return find(this.shapes, { name: this.shapeTypes.clientDate });
      }
    },

    startDrawing(e) {
      this.isDrawing = true;
      this.drawingArea.startDrawing(e);
    },

    updateDrawing(e) {
      this.drawingArea.updateDrawing(e);
    },

    endDrawing() {
      switch (this.drawingShape) {
        case this.shapeTypes.lawyerSignature:
        case this.shapeTypes.clientSignature:
          this.drawingArea.endDrawing(this.drawShape);
          break;
        case this.shapeTypes.lawyerText:
        case this.shapeTypes.clientText:
          this.drawingArea.endDrawing(this.drawShape, { text: '* Text' });
          break;
        case this.shapeTypes.lawyerDate:
        case this.shapeTypes.clientDate:
          this.drawingArea.endDrawing(this.drawShape, { text: '* Date' });
          break;
      }

      this.drawingShape = null;
      this.isDrawing = false;
    },

    drawShape(guide) {
      let attrs = {
        id: this.drawingShape + new Date().getTime(),
        name: this.drawingShape,
        page: this.pageNum,
        role: this.role,
        x: guide.x,
        y: guide.y,
        width: guide.width,
        height: guide.height,
        scaleX: 1,
        scaleY: 1,
        text: guide.text,
        pixelRatio: this.pixelRatio
      };

      let shape = Pdf.drawShape(attrs, this.genericTypeOf(attrs), {
        visible: true,
        draggable: true,
        onUpdateShapeTools: this.updateShapeTools,
        onShapeChange: this.shapeChanged
      });

      this.shapes.push(shape.attrs);
    },

    updateShapeTools(showTools) {
      if (this.selectedShape) {
        if (this.selectedShape.attrs.draggable) {
          this.showShapeTools = showTools;
          this.shapeToolStyle = {
            'left': this.selectedShape.attrs.x + 'px',
            'top': this.selectedShape.attrs.y + 'px'
          };
        }
        else {
          this.showShapeTools = false;
        }
      }
    },

    shapeChanged(s) {
      let shape = find(this.shapes, { id: s.attrs.id });

      shape.x = s.attrs.x;
      shape.y = s.attrs.y;
      shape.scaleX = s.attrs.scaleX;
      shape.scaleY = s.attrs.scaleY;
      shape.width = s.attrs.width;
      shape.height = s.attrs.height;
    },

    getTransformer(attrs) {
      if (this.genericTypeOf(attrs) === 'signature') {
        return PdfShape.getSignatureTransformer(attrs.draggable);
      }
      else {
        return PdfShape.getTextTransformer(attrs.draggable);
      }
    },

    destroyShape() {
      if (this.selectedShape) {
        Pdf.stage.find('Transformer').destroy();
        Pdf.stage.find('#' + this.selectedShape.attrs.id).destroy();
        Pdf.drawLayer.draw();

        const shapeIndex = this.shapes.findIndex(shape => shape.id === this.selectedShape.attrs.id);

        if (shapeIndex !== -1) {
          this.shapes.splice(shapeIndex, 1);
        }

        if (this.selectedShape.attrs.name === 'lawyerText') {
          this.text = null;
        }
        else if (this.selectedShape.attrs.name === 'lawyerDate') {
          this.date = null;
        }
        else if (this.selectedShape.attrs.name === 'lawyerSignature') {
          this.clearSignaturePad();
        }

        this.selectedShape = null;
        this.showShapeTools = false;
      }
    },

    loadEventHandlers() {
      const vueInstance = this;

      setTimeout(() => {
        vueInstance.loadDrawingEvents();
        vueInstance.loadDestroyEvent();
      });
    },

    unloadEventHandlers() {
      this.unloadDrawingEvents();
      this.unloadDestroyEvent();
    },

    loadDrawingEvents() {
      Pdf.stage.on('click tap', this.konvaClickTapHandler);
      Pdf.stage.on('mouseover', this.konvaMouseoverHandler);
      Pdf.stage.on('mouseleave', this.konvaMouseleaveHandler);
      Pdf.stage.on('mousedown', this.konvaMousedownHandler);
      Pdf.stage.on('mousemove', this.konvaMousemoveHandler);
      Pdf.stage.on('mouseup', this.konvaMouseupHandler);
    },

    unloadDrawingEvents() {
      Pdf.stage.off('click tap', this.konvaClickTapHandler);
      Pdf.stage.off('mouseover', this.konvaMouseoverHandler);
      Pdf.stage.off('mouseleave', this.konvaMouseleaveHandler);
      Pdf.stage.off('mousedown', this.konvaMousedownHandler);
      Pdf.stage.off('mousemove', this.konvaMousemoveHandler);
      Pdf.stage.off('mouseup', this.konvaMouseupHandler);
    },

    loadDestroyEvent() {
      let container = Pdf.stage.container();

      container.tabIndex = 1;
      container.addEventListener('keydown', this.konvaDestroyHandler);
    },

    unloadDestroyEvent() {
      Pdf.stage.container().removeEventListener('keydown', this.konvaDestroyHandler);
    },

    // ********** Signature Pad Functions **********

    loadSignaturePad() {
      const vueInstance = this;

      setTimeout(() => {
        let canvas = vueInstance.$refs[vueInstance.signaturePadId];

        vueInstance.signaturePad = new window.SignaturePad(canvas, {
          minWidth: 1,
          maxWidth: 1.5,
          penColor: 'rgb(66, 133, 244)',
          backgroundColor: 'rgba(255, 255, 255, 0)',
          onEnd: vueInstance.onEndSigning
        });

        if (vueInstance.signature) {
          let signatureShape = vueInstance.getSignatureShape();

          vueInstance.signaturePad.fromDataURL(vueInstance.signature, { ratio: 1 });

          if (signatureShape) {
            let drawnShape = Pdf.stage.findOne('#' + signatureShape.id + 'rect');

            PdfShape.fillSignatureImage(drawnShape, signatureShape, vueInstance.signature, () => {
              if (signatureShape.page === vueInstance.pageNum) {
                Pdf.drawLayer.draw();
              }
            });
          }
        }
      });
    },

    hasSignature() {
      return this.signaturePad && !this.signaturePad.isEmpty();
    },

    clearSignaturePad() {
      if (this.signaturePad) {
        let signatureShape = this.getSignatureShape();

        this.signaturePad.clear();
        this.engagementLetterConfig.signature = null;
        this.signatureData = null;

        if (signatureShape) {
          let drawnShape = Pdf.stage.findOne('#' + signatureShape.id + 'rect');

          signatureShape.signed = false;
          signatureShape.fillPatternImage = null;

          if (drawnShape) {
            drawnShape.fill('#fbf5e8');
            Pdf.drawLayer.draw();
          }
        }
      }
    },

    onEndSigning() {
      let signatureShape = this.getSignatureShape();

      if (signatureShape) {
        let signatureData = this.signaturePad.toDataURL();
        let drawnShape = Pdf.stage.findOne('#' + signatureShape.id + 'rect');

        signatureShape.signed = true;
        this.signatureData = signatureData;

        PdfShape.fillSignatureImage(drawnShape, signatureShape, signatureData, () => {
          if (signatureShape.page === this.pageNum) {
            Pdf.drawLayer.draw();
          }
        });
      }
    },

    // ********** Form and Signing Functions **********

    resetForm() {
      if (this.isLawyer) {
        if (this.hasShape(this.shapeTypes.lawyerText)) {
          this.text = this.getTextShape().text;
        }
        if (this.hasShape(this.shapeTypes.lawyerDate)) {
          this.date = this.convertDate(new Date(this.getDateShape().text));
        }
      }
    },

    textFieldFocused() {
      if (this.pdfLoaded) {
        let textShape = this.getTextShape();

        if (textShape) {
          let drawnShape = Pdf.stage.findOne('#' + textShape.id);

          if (drawnShape) {
            let tr = this.getTransformer(drawnShape.attrs);

            tr.attachTo(drawnShape);
            this.selectedShape = drawnShape;
          }
        }
      }
    },

    textFieldBlurred() {
      if (this.pdfLoaded) {
        this.deselectAllShapes();
      }
    },

    convertDate(date) {
      return DateFilter.filter(new Date(date));
    },

    isInvalid() {
      if (this.isOnStep(this.steps.define)) {
        return !this.hasShape(this.shapeTypes.clientSignature);
      }
      else if (this.isLawyer) {
        return (this.hasShape(this.shapeTypes.lawyerSignature) && !this.hasSignature()) ||
          (this.hasShape(this.shapeTypes.lawyerText) && !this.text) ||
          (this.hasShape(this.shapeTypes.lawyerDate) && !this.date);
      }
      else if (this.isClient) {
        return (this.hasShape(this.shapeTypes.clientSignature) && !this.hasSignature()) ||
          (this.hasShape(this.shapeTypes.clientText) && !this.text) ||
          (this.hasShape(this.shapeTypes.clientDate) && !this.date);
      }
    },

    submit() {
      let response = {
        coord: this.shapes,
        signature: this.signatureData
      };

      if (this.isClient) {
        let coord = JSON.stringify(response.coord);
        let signature = response.signature;
        this.saving = true;

        Document.updateEngagementLetter(
          this.role, this.engagementLetterConfig.matterId, null, signature, coord
        ).then((res) => {
          res = JSON.parse(res);
          response.url = res.url;

          this.saving = false;

          this.onSubmit(response);
          this.closeModal();
        });
      }
      else {
        let signatureShape = this.getSignatureShape();

        if (signatureShape) {
          signatureShape.fillPatternImage = null;
        }

        this.onSubmit(response);
        this.closeModal();
      }
    },

    // ********** Event Handlers **********

    konvaClickTapHandler(e) {
      if (this.drawingShape) { return; }

      let target = e.target.attrs.name ? e.target : e.target.parent;

      if (e.target === Pdf.stage) {
        this.deselectAllShapes();
      }
      else if (some(this.shapeTypes, function (v) { return target.hasName(v); })) {
        if (this.selectedShape) { this.deselectAllShapes(); }

        let tr = this.getTransformer(target.attrs);

        Pdf.drawLayer.add(tr);
        tr.attachTo(target);
        Pdf.drawLayer.draw();

        this.selectedShape = target;
        this.updateShapeTools(true);
      }
    },

    konvaMouseoverHandler(e) {
      if (this.drawingShape) {
        Pdf.stage.container().style.cursor = 'crosshair';
      }
      else if (e.target !== Pdf.stage) {
        Pdf.stage.container().style.cursor = 'pointer';
      }
      else {
        Pdf.stage.container().style.cursor = 'default';
      }
    },

    konvaMouseleaveHandler() {
      Pdf.stage.container().style.cursor = 'default';
      document.activeElement.blur();
    },

    konvaMousedownHandler(e) {
      if (this.drawingShape) {
        this.startDrawing(e);
      }
      else if (e.target !== Pdf.stage) {
        Pdf.stage.container().style.cursor = 'grabbing';
      }
    },

    konvaMousemoveHandler(e) {
      if (this.isDrawing) {
        this.updateDrawing(e);
      }
    },

    konvaMouseupHandler(e) {
      this.endDrawing(e);

      if (e.target !== Pdf.stage) {
        Pdf.stage.container().style.cursor = 'pointer';
      }
      else {
        Pdf.stage.container().style.cursor = 'default';
      }
    },

    konvaDestroyHandler(e) {
      e.preventDefault();

      if (e.keyCode === 46 || e.keyCode === 8) {
        this.destroyShape();
      }
    },

    dateClass() {
      return 'uib-day';
    },

    dateYearMonthFormat(year, month) {
      month = new Date(year, month).toLocaleString('default', { month: 'long' });
      return `${month} ${year}`;
    }
  }
};
</script>
