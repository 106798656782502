import PdfShape from './pdf-shape';
import * as pdfjsLib from 'pdfjs-dist';
class PdfRenderer {
  constructor() {
    window.pdfjsLib = pdfjsLib;
    if (window.pdfjsLib) {
      window.pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js';
    }

    this.containerWidth = null;
    this.renderShapes = null;
    this.pageScale = 1;
  }

  init(documentUrl, containerId, containerWidth, renderShapes, onComplete) {
    this.containerWidth = containerWidth;
    this.renderShapes = renderShapes;

    this.loadKonva(containerId);
    this.loadPdfDocument(documentUrl, onComplete);
  }

  loadKonva(containerId) {
    this.stage = new Konva.Stage({ container: containerId, width: this.containerWidth });

    this.pdfLayer = new Konva.Layer();
    this.pdfLayerCanvas = this.pdfLayer.getCanvas();
    this.pdfLayerContext = this.pdfLayer.getContext();
    this.stage.add(this.pdfLayer);

    if (this.renderShapes) {
      this.drawLayer = new Konva.Layer();
      this.stage.add(this.drawLayer);
    }
  }

  loadPdfDocument(documentUrl, onComplete) {
    this.pageNum = 1;
    this.numPages = 1;

    if (window.pdfjsLib) {
      window.pdfjsLib.getDocument(documentUrl).promise.then((pdfDoc) => {
        this.pdfDoc = pdfDoc;
        this.numPages = this.pdfDoc.numPages;
        this.renderPage();

        onComplete();
      });
    }
  }

  renderPage() {
    this.pageRendering = true;
    this.stage.clear();

    this.pdfDoc.getPage(this.pageNum).then((page) => {
      var viewport = page.getViewport({ scale: this.viewportScaleForPage(page) });
      var renderContext = {
        canvasContext: this.pdfLayerContext,
        viewport: viewport
      };

      this.stage.height(_.floor(viewport.height));

      page.render(renderContext).promise.then(() => {
        this.pageRendering = false;

        if (this.renderShapes) {
          this.drawLayer.destroyChildren();
          this.renderShapes();
        }

        // New page rendering is pending
        if (this.pageNumPending) {
          this.renderPage(this.pageNumPending);
          this.pageNumPending = null;
        }
      });
    });
  }

  viewportScaleForPage(page) {
    this.pageScale = this.containerWidth / page.getViewport({ scale: 1 }).width;
    return this.pageScale;
  }

  queueRenderPage() {
    if (this.pageRendering) {
      this.pageNumPending = this.pageNum;
    }
    else {
      this.renderPage();
    }
  }

  isFirstPage() {
    return this.pageNum <= 1;
  }

  isLastPage() {
    return this.pageNum >= this.numPages;
  }

  prevPage() {
    if (this.isFirstPage()) { return; }

    this.pageNum--;
    this.queueRenderPage();
  }

  nextPage() {
    if (this.isLastPage()) { return; }

    this.pageNum++;
    this.queueRenderPage();
  }

  goToPage(pageNum) {
    if (pageNum < 1 || pageNum > this.numPages) { return; }

    this.pageNum = pageNum;
    this.queueRenderPage();
  }

  drawShape(attrs, type, options) {
    var shape;

    attrs.pageScale = this.pageScale;

    if (type === 'signature') {
      shape = PdfShape.drawSignature(attrs, options);
    }
    else {
      shape = PdfShape.drawText(attrs, options);
    }

    if (shape) {
      this.drawLayer.add(shape).draw();
    }

    return shape;
  }
}

const instance = new PdfRenderer();

export default instance;
