class PdfDrawingArea {
  init(layer) {
    this.layer = layer;
    this.drawingPos = {
      start: {},
      end: {}
    };

    this.drawGuide();
  }

  drawGuide() {
    this.guide = new Konva.Rect({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      stroke: '#999',
      draggable: false,
      dash: [4, 4]
    });

    this.guide.listening(false);
    this.layer.add(this.guide).draw();
  }

  startDrawing(e) {
    this.drawingPos = {
      start: {
        x: e.evt.offsetX, y: e.evt.offsetY
      },
      end: {
        x: e.evt.offsetX, y: e.evt.offsetY
      }
    };

    this.guide.x(e.evt.offsetX);
    this.guide.y(e.evt.offsetY);
    this.guide.width(0);
    this.guide.height(0);
    this.guide.visible(true);
  }

  updateDrawing(e) {
    this.drawingPos.end = {
      x: e.evt.offsetX,
      y: e.evt.offsetY
    };

    var posRect = this.reverse(this.drawingPos.start, this.drawingPos.end);

    this.guide.x(posRect.startX);
    this.guide.y(posRect.startY);
    this.guide.width(posRect.endX - posRect.startX);
    this.guide.height(posRect.endY - posRect.startY);

    this.layer.draw();
  }

  reverse(start, end) {
    var startX = start.x;
    var startY = start.y;
    var endX = end.x;
    var endY = end.y;
    var diff;

    if (startX > endX) {
      diff = Math.abs(startX - endX);
      startX = endX;
      endX = startX + diff;
    }

    if (startY > endY) {
      diff = Math.abs(startY - endY);
      startY = endY;
      endY = startY + diff;
    }

    return ({ startX: startX, startY: startY, endX: endX, endY: endY });
  }

  endDrawing(onEndDrawing, options) {
    var drawingArea = {
      x: this.guide.x(),
      y: this.guide.y(),
      width: this.guide.width(),
      height: this.guide.height()
    };
    drawingArea = Object.assign(drawingArea, options);

    this.guide.visible(false);

    onEndDrawing(drawingArea);
  }
}

export default PdfDrawingArea;
